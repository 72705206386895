import React, { useEffect, useState } from 'react';
import { getSrc } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import loadable from '@loadable/component';
import SEO from '$components/SEO';
import BlockName from '$utils/BlockName';
import hashCode from '$utils/hashCode';
import Cta from '$components/Cta';

const BlockArticleListing = loadable(() => import('$components/Blocks/BlockArticleListing'));
const BlockCaseEnd = loadable(() => import('$components/Blocks/BlockCaseEnd'));
const BlockCaseHero = loadable(() => import('$components/Blocks/BlockCaseHero'));
const BlockCaseImageFigure = loadable(() => import('$components/Blocks/BlockCaseImageFigure'));
const BlockCaseListing = loadable(() => import('$components/Blocks/BlockCaseListing'));
const BlockCenteredText = loadable(() => import('$components/Blocks/BlockCenteredText'));
const BlockContactHero = loadable(() => import('$components/Blocks/BlockContactHero'));
const BlockCoworkers = loadable(() => import('$components/Blocks/BlockCoworkers'));
const BlockImageFullWidth = loadable(() => import('$components/Blocks/BlockImageFullWidth'));
const BlockImageMosaic = loadable(() => import('$components/Blocks/BlockImageMosaic'));
const BlockImageText = loadable(() => import('$components/Blocks/BlockImageText'));
const BlockImageTextWidth = loadable(() => import('$components/Blocks/BlockImageTextWidth'));
const BlockLink = loadable(() => import('$components/Blocks/BlockLink'));
const BlockListBlock = loadable(() => import('$components/Blocks/BlockListBlock'));
const BlockLogotypes = loadable(() => import('$components/Blocks/BlockLogotypes'));
const BlockMainHeading = loadable(() => import('$components/Blocks/BlockMainHeading'));
const BlockOfferLineup = loadable(() => import('$components/Blocks/BlockOfferLineup'));
const BlockProcessHero = loadable(() => import('$components/Blocks/BlockProcessHero'));
const BlockQuote = loadable(() => import('$components/Blocks/BlockQuote'));
const BlockStartPageHero = loadable(() => import('$components/Blocks/BlockStartPageHero'));
const BlockTwoColumns = loadable(() => import('$components/Blocks/BlockTwoColumns'));
const BlockWebinarForm = loadable(() => import('$components/Blocks/BlockWebinarForm'));
const ScrollingCircles = loadable(() => import('$components/ScrollingCircles'));

interface BlockPageProps {
  pageContext: any;
  location: Location;
}

const BlockPage: React.FC<BlockPageProps> = ({ pageContext, location }) => {
  const data = useStaticQuery(graphql`
    query {
      cms {
        defaultCta {
          Teasers {
            Heading
            Image {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 240)
                }
              }
            }
            Link {
              Title
              URL
            }
            Text
          }
        }
      }
    }
  `);

  const defaultTeasers = data.cms.defaultCta.Teasers;
  const [ctaTeasers, setCtaTeasers] = useState(defaultTeasers);
  const { page } = pageContext;

  useEffect(() => {
    setCtaTeasers(page.Teaser);
  }, []);

  // @todo add types
  if (!page.Blocks || page.Blocks.length === 0) {
    return <h1>{page.Title}</h1>;
  }

  const teasers = page && page.Teaser && page.Teaser.length > 0 ? ctaTeasers : defaultTeasers; // Page will be undefined for static pages, e.g. 404

  // Will default to true on static pages (e.g. 404)
  const showTeasers = !page || page.Teasers === true;

  const [showCircles, setShowCircles] = useState(null);

  const circleIndex = hashCode(location.pathname) % 4;

  useEffect(() => {
    setShowCircles(true);
  }, []);

  const blocks = [];

  // Identify all supported blocks and render them
  // @todo add types for all blocks
  page.Blocks.forEach((block) => {
    switch (block.__typename) {
      case BlockName.ContactHero:
        blocks.push(
          <BlockContactHero
            heading={block.Heading}
            column1={block.Column1}
            column2={block.Column2}
            column3={block.Column3}
            key={`${block.__typename}_${block.id}`}
          />
        );
        break;
      case BlockName.CoworkersBlock:
        blocks.push(
          <BlockCoworkers key={`${block.__typename}_${block.id}`} coworkers={block.Coworkers} heading={block.Heading} />
        );
        break;
      case BlockName.MainHeading:
        blocks.push(<BlockMainHeading key={`${block.__typename}_${block.id}`} text={block.Text} />);
        break;
      case BlockName.OfferLineup:
        blocks.push(
          <BlockOfferLineup key={`${block.__typename}_${block.id}`} heading={block.Heading} offers={block.Offers} />
        );
        break;
      case BlockName.StartPageHero:
        blocks.push(
          <BlockStartPageHero
            key={`${block.__typename}_${block.id}`}
            h1={block.H1}
            h2={block.H2}
            content={block.Content}
            ctaTo={block.Button.URL}
            ctaText={block.Button.Title}
          />
        );
        break;
      case BlockName.ImageAndText:
        blocks.push(
          <BlockImageText
            key={`${block.__typename}_${block.id}`}
            imagePosition={block.ImagePosition}
            title={block.Title}
            shortDescription={block.ShortDescription}
            image={block.Image}
            heading={block.Heading}
            content={block.Content}
            link={block.Link}
            id={block.id}
            block={block}
          />
        );
        break;
      case BlockName.ContentBlock:
        blocks.push(<BlockCenteredText key={`${block.__typename}_${block.id}`} content={block.Content} />);
        break;
      case BlockName.CaseHero:
        blocks.push(
          <BlockCaseHero
            key={`${block.__typename}_${block.id}`}
            h1={block.H1}
            h1AfterHighlight={block.H1AfterHighlight}
            h1Highlight={block.H1Highlight}
            h2={block.H2}
            contentColumn1={block.ContentColumn1}
            contentColumn2={block.ContentColumn2}
            tags={block.Tags}
          />
        );
        break;
      case BlockName.Logotypes:
        if (block?.image_with_link?.length > 0) {
          blocks.push(
            <BlockLogotypes
              key={`${block.__typename}_${block.id}`}
              heading={block.Heading}
              logotypes={block.image_with_link}
            />
          );
        }
        break;
      case BlockName.ListBlock:
        blocks.push(<BlockListBlock key={`${block.__typename}_${block.id}`} list={block.List} />);
        break;
      case BlockName.TwoColumns:
        blocks.push(
          <BlockTwoColumns key={`${block.__typename}_${block.id}`} column1={block.Column1} column2={block.Column2} />
        );
        break;
      case BlockName.CaseImageFigure:
        blocks.push(
          <BlockCaseImageFigure
            key={`${block.__typename}_${block.id}`}
            image={block.Image}
            content={block.Content}
            figure={block.Figure}
            figureDescription={block.FigureDescription}
          />
        );
        break;
      case BlockName.ImageMosaic:
        blocks.push(<BlockImageMosaic key={`${block.__typename}_${block.id}`} images={block.Images} />);
        break;
      case BlockName.ImageFullWidth:
        blocks.push(
          <BlockImageFullWidth key={`${block.__typename}_${block.id}`} caption={block.Caption} image={block.Image} />
        );
        break;
      case BlockName.ImageTextWidth:
        blocks.push(
          <BlockImageTextWidth key={`${block.__typename}_${block.id}`} image={block.Image} caption={block.Caption} />
        );
        break;
      case BlockName.ProcessHero:
        blocks.push(
          <BlockProcessHero
            key={`${block.__typename}_${block.id}`}
            h1={block.H1}
            h2={block.H2}
            h2Highlight={block.H2Highlight}
            content={block.Content}
            h2AfterHighlight={block.h2AfterHighlight}
            button={block.Button}
          />
        );
        break;
      case BlockName.CaseEnd:
        blocks.push(
          <BlockCaseEnd
            image={block.Coworker.Image}
            name={block.Coworker.Name}
            role={block.Coworker.Role}
            phone={block.Coworker.Phone}
            email={block.Coworker.Email}
            key={`${block.__typename}_${block.id}`}
          />
        );
        break;
      case BlockName.ArticleListing:
        blocks.push(<BlockArticleListing key={`${block.__typename}_${block.id}`} />);
        break;
      case BlockName.CaseListing:
        blocks.push(<BlockCaseListing key={`${block.__typename}_${block.id}`} />);
        break;
      case BlockName.WebinarForm:
        blocks.push(<BlockWebinarForm key={`${block.__typename}_${block.id}`} />);
        break;
      case BlockName.Quote:
        blocks.push(
          <BlockQuote
            key={`${block.__typename}_${block.id}`}
            imageHasTransparentBackground={block.ImageHasTransparentBackground}
            imagePlacement={block.ImagePlacement}
            name={block.Name}
            quote={block.Quote}
            title={block.Title}
            image={block.Image}
            showImage={!!block.Image}
          />
        );
        break;
      case BlockName.Link:
        blocks.push(
          <BlockLink
            key={`${block.__typename}_${block.id}`}
            heading={block.Heading}
            large={block.Large}
            linkBlockLinks={block.LinkBlockLinks}
          />
        );
        break;
      default:
        break;
    }
  });

  return (
    <div>
      {showCircles && <ScrollingCircles location={location} circleIndex={circleIndex} />}
      <SEO
        title={page.PageTitle}
        description={page.ShortDescription}
        image={page.Image ? getSrc(page.Image.imageFile) : null}
        slug={page.Slug}
        type={page.__typename}
        datePublished={page.created_at}
        dateModified={page.updated_at}
        isArticle={page.__typename === 'CMS_Article'}
      />
      {blocks}
      {showTeasers && <Cta items={teasers} />}
    </div>
  );
};

export default BlockPage;
