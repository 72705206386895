const hashCode = (s: string): number => {
  let h = 0;
  for (let i = 0; i < s.length; i += 1) {
    h += s.charCodeAt(i);
  }

  return h;
};

export default hashCode;
